import { defineComponent, ref, reactive, toRefs, onMounted, watch } from 'vue';
import { ArrowRight } from '@element-plus/icons-vue';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { getProductsDeail } from "@/http/product";
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { imgUrl } from '@/utils/urlManage';
import emitter from "@/mitt";
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const carouselRef = ref();
    const data = reactive({
      // 产品数据
      productDeatil: [],
      flag: true,
      onlyDetail: {},
      num: 0
    });
    const methods = {
      /**
       * 点击切换轮播图
       */
      changeCarousel(i) {
        var _carouselRef$value;

        (_carouselRef$value = carouselRef.value) === null || _carouselRef$value === void 0 ? void 0 : _carouselRef$value.setActiveItem(i);
        data.num = i;
      },

      //获取右侧数据
      getProductDeailMethod(id) {
        data.flag = false;
        router.push(`/product/detail/${id}`); // getProductsDeail({ id: id == -1 ? route.params.id : id }).then((res) => {
        //   if (res.data.code == 200) {
        //     if (res.data.data == undefined) return
        //     data.onlyDetail = res.data.data
        //   } else {
        //     ElMessage.error(res.data.message)
        //   }
        // })
      },

      changeNum(i) {
        data.num = i;
      },

      /**
       * 查看详情
       */
      lookDetail(str) {
        data.flag = false;
        getProductsDeail({
          id: str == -1 ? route.params.id : str
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.data == undefined) return;
            data.onlyDetail = res.data.data;
          } else {
            ElMessage.error(res.data.message);
          }
        });
      },

      init() {
        if (route.path.slice(0, 13) == '/product/list') {
          data.flag = true;
          data.productDeatil = sessionStorage.getItem('productDeatil') ? JSON.parse(sessionStorage.getItem('productDeatil') || '') : "";
        } else if (route.path.slice(0, 15) == '/product/detail') {
          data.flag = false;
          methods.lookDetail(-1);
        }
      }

    };
    emitter.on("get-product-detail", () => {
      data.productDeatil = sessionStorage.getItem('productDeatil') ? JSON.parse(sessionStorage.getItem('productDeatil') || '') : "";
    });
    onMounted(() => {
      data.productDeatil = [];

      if (route.path.slice(0, 8) == "/product") {
        methods.init();
      }
    }); // 监听路由变化[id]

    watch(() => route.path, (newValue, oldValue) => {
      if (newValue.slice(0, 15) == '/product/detail') {
        data.flag = false;
        methods.lookDetail(-1);
      } else {
        data.flag = true;
        data.productDeatil = sessionStorage.getItem('productDeatil') ? JSON.parse(sessionStorage.getItem('productDeatil') || '') : "";
      }
    });
    return { ...toRefs(data),
      ArrowRight,
      ...methods,
      imgUrl,
      carouselRef,
      route
    };
  }

});